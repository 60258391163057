import React, { useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const TruckTotalsCard = ({ totals, totalsSecond }) => {
  const fields = [
    { label: 'Gross', accessor: 'gross' },
    { label: 'Miles', accessor: 'miles' },
    { label: 'Real Miles', accessor: 'miles_real' },
    { label: 'Labor', accessor: 'labor' },
    { label: 'RM', accessor: 'rm' },
    { label: 'Diesel', accessor: 'diesel' },
    { label: 'Fuel Efficiency', accessor: 'fuel_efficiency' },
    { label: 'Tolls', accessor: 'tolls' },
    { label: 'Dispatch', accessor: 'dispatch' },
    { label: 'Payment', accessor: 'payment' },
    { label: 'Insurance', accessor: 'insurance' },
    { label: 'Rental', accessor: 'rental' },
    { label: 'Cameras', accessor: 'cameras' },
    { label: 'Admin', accessor: 'admin' },
    { label: 'Others', accessor: 'others' },
  ];

  // Log the content of totals and totalsSecond to the console
  useEffect(() => {
    console.log("Totals for first truck:", totals);
    console.log("Totals for second truck:", totalsSecond);
  }, [totals, totalsSecond]);

  // Function to render a value (check if valid)
  const renderValue = (fieldValue) => {
    if (!fieldValue || !fieldValue.Valid) {
      return 'N/A'; // Return 'N/A' if field is null or not valid
    }
    return typeof fieldValue.Float64 !== 'undefined' ? `$${fieldValue.Float64}` : fieldValue;
  };

  // Helper function to find totals by period
  const findTotalsByPeriod = (totalsArray, period) => {
    return totalsArray.find((total) => total.period === period);
  };

  // Get all unique periods from both totals and totalsSecond
  const allPeriods = Array.from(
    new Set([...totals.map((t) => t.period), ...totalsSecond.map((t) => t.period)])
  ).sort(); // Sort the periods in ascending order

  const renderTruckData = (truckTotals, truckLabel) => (
    <>
      {fields.map((field, index) => (
        <TableRow key={field.accessor}>
          {/* Display truck ID only in the first row for each truck */}
          {index === 0 && (
            <TableCell rowSpan={fields.length} style={{ fontWeight: 'bold', position: 'sticky', left: 0, background: '#fff' }}>
              {truckLabel}
            </TableCell>
          )}
          <TableCell>{field.label}</TableCell>
          {allPeriods.map((period) => {
            const truckData = findTotalsByPeriod(truckTotals, period);
            return (
              <TableCell key={period}>
                {truckData ? renderValue(truckData[field.accessor]) : 'N/A'}
              </TableCell>
            );
          })}
        </TableRow>
      ))}
    </>
  );

  return (
    <TableContainer component={Paper}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Truck ID</TableCell>
            <TableCell>Field</TableCell>
            {allPeriods.map((period) => (
              <TableCell key={period}>Period {period}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {/* Render data for the first truck */}
          {totals.length > 0 && renderTruckData(totals, totals[0]?.truck?.truck_id)}

          {/* Render data for the second truck below the first truck */}
          {totalsSecond.length > 0 && renderTruckData(totalsSecond, totalsSecond[0]?.truck?.truck_id)}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TruckTotalsCard;
