import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom'; // Use navigate for redirection and Link for navigation
import axios from 'axios';

const Login = () => {
    const [formData, setFormData] = useState({
        username: '',
        password: ''
    });

    const navigate = useNavigate(); // Hook to navigate to different routes

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default GET request

        // POST request to the /login endpoint
        try {
            const response = await axios.post('/api/login', formData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            // Assuming the response contains a JWT token
            const { token } = response.data;

            // Store the JWT token in localStorage
            localStorage.setItem('token', token);

            // Redirect the user to the home page ("/")
            navigate('/');
        } catch (error) {
            console.error("There was an error logging in!", error.response?.data || error.message);
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 300, margin: 'auto', mt: 4 }}>
            <TextField
                fullWidth
                label="Username"
                name="username"
                value={formData.username}
                onChange={handleChange}
                margin="normal"
                required
            />
            <TextField
                fullWidth
                label="Password"
                name="password"
                type="password"
                value={formData.password}
                onChange={handleChange}
                margin="normal"
                required
            />
            <Button variant="contained" type="submit" fullWidth sx={{ mt: 2 }}>
                Login
            </Button>

            {/* Hyperlink to the register page */}
            <Typography variant="body2" align="center" sx={{ mt: 2 }}>
                Don't have an account? <Link to="/register">Register here</Link>
            </Typography>
        </Box>
    );
};

export default Login;
