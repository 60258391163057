import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import {
  CssBaseline,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Toolbar,
  AppBar,
  Typography,
  Box,
  Button,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PeopleIcon from '@mui/icons-material/People';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import TrailerIcon from '@mui/icons-material/EmojiTransportation';
import TollIcon from '@mui/icons-material/LocalAtm';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import SecurityIcon from '@mui/icons-material/Security';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BusinessIcon from '@mui/icons-material/Business';  // Import the icon for Company
//import ParsedMessages from './ParsedMessages';
import Register from './Register';
import Login from './Login';
import PrivateRoute from './PrivateRoute';
import Loads from './loads/Loads';
import UploadExcel from './reports/UploadExcel';
import TotalTableOld from './reports/TotalTableOld';
import TruckTotalsTable from './reports/TruckTotalsTable';
import TotalHolder from './reports/TotalHolder';
import TruckTable from './trucks/TruckTable';
import TrailerTable from './trailers/TrailerTable';
import DriverTable from './drivers/DriverTable';
import CreateCompany from './manage/CreateCompany';

const drawerWidth = 240;

const navigationItems = [
  { text: 'Company', icon: <BusinessIcon />, path: '/company' },
  { text: 'Loads', icon: <LocalShippingIcon />, path: '/loads' },
  { text: 'Users', icon: <PeopleIcon />, path: '/users' },
  { text: 'Drivers', icon: <DirectionsCarIcon />, path: '/drivers' },
  { text: 'Admin', icon: <AdminPanelSettingsIcon />, path: '/admin' },
  { text: 'Trucks', icon: <DirectionsCarIcon />, path: '/trucks' },
  { text: 'Trailers', icon: <TrailerIcon />, path: '/trailers' },
  { text: 'Tolls', icon: <TollIcon />, path: '/tolls' },
  { text: 'Fuel', icon: <LocalGasStationIcon />, path: '/fuel' },
  { text: 'Insurance', icon: <SecurityIcon />, path: '/insurance' },
  { text: 'Accounting', icon: <LocalAtmIcon />, path: '/accounting' },
  { text: 'Reports', icon: <AssessmentIcon />, path: '/reports' },
  { text: 'Settings', icon: <SettingsIcon />, path: '/settings' },
];

function App() {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location
  const isMobile = useMediaQuery('(max-width:600px)');
  const [mobileOpen, setMobileOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState('Dashboard');

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    console.log('Logging out...');
    navigate('/login');
  };

  // Update the current tab based on the route
  useEffect(() => {
    const currentRoute = navigationItems.find((item) => item.path === location.pathname);
    setCurrentTab(currentRoute ? currentRoute.text : 'Dashboard');
  }, [location]);

  const drawer = (
    <div>
      <Toolbar />
      <List>
        {navigationItems.map((item) => (
          <ListItem button key={item.text} onClick={() => navigate(item.path)}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` }
        }}
      >
        <Toolbar>
          {isMobile && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            {currentTab} {/* Display the current tab name */}
          </Typography>
          <Button color="inherit" onClick={handleLogout} sx={{ textTransform: 'none' }}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant={isMobile ? 'temporary' : 'permanent'}
          open={isMobile ? mobileOpen : true}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: 'background.default',
          p: 0,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/manage" element={<CreateCompany />} />
          {navigationItems.map((item) => (
            <Route
              key={item.text}
              path={item.path}
              element={
                item.path === '/loads' ? <Loads /> :
                item.path === '/admin' ? <UploadExcel /> :
                item.path === '/trucks' ? <TruckTable /> :
                item.path === '/trailers' ? <TrailerTable /> :
                item.path === '/drivers' ? <DriverTable /> :
                item.path === '/insurance' ? <TotalHolder /> :
                item.path === '/reports' ? <TotalTableOld /> :
                item.path === '/settings' ? <TruckTotalsTable /> :
                  <div>{item.text} Page</div>}
            />
          ))}
        </Routes>
      </Box>
    </Box>
  );
}

export default App;
