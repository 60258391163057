import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Typography, CircularProgress, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import TotalsTable from './TruckTotalsCard'; // Import the TotalsTable component

const TruckTotalsTable = () => {
  const [trucks, setTrucks] = useState([]);
  const [totals, setTotals] = useState([]); // For the first truck's totals
  const [totalsSecond, setTotalsSecond] = useState([]); // For the second truck's totals
  const [selectedTruckID, setSelectedTruckID] = useState(''); // First truck selection
  const [selectedSecondTruckID, setSelectedSecondTruckID] = useState(''); // Second truck selection
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch trucks data
    const fetchTrucks = async () => {
      try {
        const response = await axios.get('/api/trucks');
        const trucksData = response.data;
        setTrucks(trucksData);

        if (trucksData.length > 0) {
          const firstTruckID = trucksData[0].id; // Using 'id' from the truck object
          setSelectedTruckID(firstTruckID); // Set the first truck ID as the default selection
          fetchTotals(firstTruckID, setTotals); // Fetch totals for the first truck
        }
      } catch (error) {
        console.error("Error fetching trucks data: ", error);
        setError('Error fetching trucks data');
      } finally {
        setLoading(false);
      }
    };

    fetchTrucks();
  }, []);

  // Function to fetch totals for a selected truck by its 'id' or fetch totals for all trucks if 'All Trucks' is selected
  const fetchTotals = async (truckID, setTotalsFunc) => {
    try {
      const url = truckID === 'all' ? '/api/totals' : `/api/totals?truck=${truckID}`;
      const response = await axios.get(url);
      console.log('API response:', response.data); // Check the response structure

      // Ensure the response is an array, or convert the object to an array
      const data = response.data;
      const totalsArray = Array.isArray(data) ? data : Object.values(data); // Transform to array if needed
      setTotalsFunc(totalsArray);
    } catch (error) {
      console.error("Error fetching totals: ", error);
      setError('Error fetching totals');
    }
  };

  // Handle truck selection change for the first dropdown
  const handleTruckChange = (event) => {
    const newTruckID = event.target.value;
    setSelectedTruckID(newTruckID);
    fetchTotals(newTruckID, setTotals); // Fetch totals for the newly selected truck
  };

  // Handle second truck selection change for the second dropdown
  const handleSecondTruckChange = (event) => {
    const newTruckID = event.target.value;
    setSelectedSecondTruckID(newTruckID);
    fetchTotals(newTruckID, setTotalsSecond); // Fetch totals for the second truck
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography>{error}</Typography>;
  }

  if (!trucks.length) {
    return <Typography>No trucks available</Typography>;
  }

  return (
    <div>
      <Typography variant="h4">Select Trucks to Compare</Typography>

      {/* First Truck Dropdown */}
      <FormControl fullWidth>
        <InputLabel id="truck-select-label">First Truck ID</InputLabel>
        <Select
          labelId="truck-select-label"
          value={selectedTruckID}
          onChange={handleTruckChange}
        >
          <MenuItem value="all">All Trucks</MenuItem> {/* Special Option for All Trucks */}
          {trucks.map((truck) => (
            <MenuItem key={truck.id} value={truck.id}>
              {truck.truck_id} {/* Display the 'truck_id', but use 'id' as the value */}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Second Truck Dropdown */}
      <FormControl fullWidth style={{ marginTop: '20px' }}>
        <InputLabel id="second-truck-select-label">Second Truck ID</InputLabel>
        <Select
          labelId="second-truck-select-label"
          value={selectedSecondTruckID}
          onChange={handleSecondTruckChange}
        >
          <MenuItem value="all">All Trucks</MenuItem> {/* Special Option for All Trucks */}
          {trucks.map((truck) => (
            <MenuItem key={truck.id} value={truck.id}>
              {truck.truck_id} {/* Display the 'truck_id', but use 'id' as the value */}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Display Totals for Both Trucks */}
      {(totals.length > 0 || totalsSecond.length > 0) && (
        <>
          <Typography variant="h5" style={{ marginTop: '20px' }}>
            Totals Comparison for Selected Trucks
          </Typography>

          <TotalsTable totals={totals} totalsSecond={totalsSecond} />
        </>
      )}
    </div>
  );
};

export default TruckTotalsTable;
