import React from 'react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';

const LoadCard = ({ load }) => {
  // Helper functions to find pickup and delivery locations
  const getPickupLocation = () => {
    if (!load?.locations || load.locations.length === 0) {
      return null; // Return null if no locations available
    }
    return load.locations
      .filter((loc) => loc.type === 0)
      .reduce((prev, curr) => (prev.location_order < curr.location_order ? prev : curr), load.locations[0]);
  };

  const getDeliveryLocation = () => {
    if (!load?.locations || load.locations.length === 0) {
      return null; // Return null if no locations available
    }
    return load.locations
      .filter((loc) => loc.type === 1)
      .reduce((prev, curr) => (prev.location_order > curr.location_order ? prev : curr), load.locations[0]);
  };

  // Extract pickup and delivery addresses
  const pickupLocation = getPickupLocation();
  const deliveryLocation = getDeliveryLocation();

  const pickupAddress = pickupLocation?.location?.address?.String || 'N/A';
  const deliveryAddress = deliveryLocation?.location?.address?.String || 'N/A';

  return (
    <Card sx={{ width: '300%', margin: 2, padding: 2 }}>
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          {/* Pickup Address and Load ID in the same line */}
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body2">
                <strong>Pickup:</strong> {pickupAddress}
              </Typography>
              <Typography variant="body2" align="right">
                <strong>Load ID:</strong> {load.load_id?.String || 'N/A'}
              </Typography>
            </Box>
          </Grid>

          {/* Gross and Distance in one single line */}
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body2">
                <strong>Gross:</strong> {load.gross?.Float64 ? `$${load.gross.Float64}` : 'N/A'}
              </Typography>
              <Typography variant="body2">
                <strong>Distance:</strong> {load.distance?.Float64 ? `${load.distance.Float64} miles` : 'N/A'}
              </Typography>
            </Box>
          </Grid>

          {/* Delivery in the bottom right */}
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Typography variant="body2" align="right">
                <strong>Destination:</strong> {deliveryAddress}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default LoadCard;
