import React, { useState } from "react";
import { Button, Typography, Box } from "@mui/material";
import axios from "axios";

const UploadExcel = () => {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      setMessage("Please select a file first.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post("/api/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setMessage(`Server Response: ${response.data}`);
    } catch (error) {
      setMessage("Error uploading file");
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" mt={5}>
      <Typography variant="h5" gutterBottom>
        Upload Excel File
      </Typography>
      <input
        type="file"
        accept=".xlsx, .xls"
        onChange={handleFileChange}
        style={{ marginBottom: "20px" }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleUpload}
      >
        Upload
      </Button>
      {message && (
        <Typography variant="body1" color="textSecondary" mt={2}>
          {message}
        </Typography>
      )}
    </Box>
  );
};

export default UploadExcel;
