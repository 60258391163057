import React, { useEffect } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, CircularProgress } from '@mui/material';

const TotalTruckCard = ({ truckId, truckNumber, totals, startPeriod, endPeriod }) => {
  // Log the received props to the console when the component mounts or updates
  useEffect(() => {
    console.log('Received props in TotalTruckCard:');
    console.log('TotalTruckCard truckId:', truckId);
    console.log('TotalTruckCard truckNumber:', truckNumber);
    console.log('TotalTruckCard totals:', totals);
    console.log('TotalTruckCard startPeriod:', startPeriod);
    console.log('TotalTruckCard endPeriod:', endPeriod);
  }, [truckId, truckNumber, totals, startPeriod, endPeriod]);

  // Helper function to handle Go's sql.Null* types and add $ or miles where applicable
  const renderFieldValue = (fieldValue, fieldName) => {
    if (typeof fieldValue === 'object' && fieldValue !== null) {
      if (fieldValue.Valid) {
        if ('Float64' in fieldValue) return addCurrencyOrMiles(fieldValue.Float64, fieldName);
        if ('Int64' in fieldValue) return addCurrencyOrMiles(fieldValue.Int64, fieldName);
        return fieldValue;
      }
      return 'N/A';
    }
    return addCurrencyOrMiles(fieldValue, fieldName);
  };

  // Helper function to add $ for certain fields or miles for others
  const addCurrencyOrMiles = (value, fieldName) => {
    const currencyFields = [
      'gross', 'labor', 'rm', 'diesel', 'tolls', 'dispatch', 'payment', 'insurance', 'rental', 'cameras', 'admin', 'others',
    ];
    const mileFields = ['miles', 'miles_real'];

    if (currencyFields.includes(fieldName)) {
      return `$${value !== null ? value.toFixed(2) : 'N/A'}`;
    }
    if (mileFields.includes(fieldName)) {
      return `${value !== null ? value.toFixed(2) : 'N/A'} miles`;
    }
    return value !== null ? value : 'N/A';
  };

  // Helper function to calculate the Monday date of a given week and year
  const getMondayDate = (week, year) => {
    const januaryFirst = new Date(year, 0, 1);
    const days = ((week - 1) * 7) + (1 - januaryFirst.getDay() + 7) % 7;
    const monday = new Date(year, 0, 1 + days);
    return monday.toLocaleDateString();
  };

  // Helper function to calculate total expenses and round to 2 decimal places
  const calculateTotalExpenses = (total) => {
    const expenseFields = ['labor', 'rm', 'diesel', 'tolls', 'dispatch', 'payment', 'insurance', 'rental', 'cameras', 'admin', 'others'];
    const totalExpenses = expenseFields.reduce((sum, field) => {
      const value = total[field]?.Float64 || 0;
      return sum + value;
    }, 0);
    return parseFloat(totalExpenses.toFixed(2)); // Round to 2 decimal places
  };

  // Helper function to calculate profit and round to 2 decimal places
  const calculateProfit = (total) => {
    const gross = total.gross?.Float64 || 0;
    const totalExpenses = calculateTotalExpenses(total);
    const profit = gross - totalExpenses;
    return parseFloat(profit.toFixed(2)); // Round to 2 decimal places
  };

  // Fields to exclude from the table
  const excludedFields = [
    'id',
    'truck_id',
    'trailer_id',
    'truck',
    'trailer',
    'created_at',
  ];

  // Field name mappings
  const fieldNameMapping = {
    period: 'Period',
    period_year: 'Period Year',
    gross: 'Gross',
    miles: 'Miles',
    miles_real: 'Miles (Real)',
    labor: 'Labor',
    rm: 'Repair & Maintenance (RM)',
    diesel: 'Diesel',
    fuel_efficiency: 'Fuel Efficiency',
    tolls: 'Tolls',
    dispatch: 'Dispatch',
    payment: 'Payment',
    insurance: 'Insurance',
    rental: 'Rental',
    cameras: 'Cameras',
    admin: 'Admin',
    others: 'Others',
  };

  if (totals.length === 0) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
        <CircularProgress /> {/* Show loading spinner while data is being fetched */}
      </div>
    );
  }

  return (
    <div style={{ margin: '20px', border: '1px solid #ccc', padding: '20px', overflowX: 'auto' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              style={{
                position: 'sticky',
                left: 0,
                background: '#fff',
                zIndex: 2, // Ensure it stays on top
                minWidth: '150px', // Ensure sufficient width for the "Truck" column
                whiteSpace: 'nowrap', // Prevent text wrapping
                paddingLeft: 0, // Remove padding on the left to eliminate the gap
              }}
            >
              Truck
            </TableCell>
            <TableCell
              style={{
                position: 'sticky',
                left: '150px', // Adjust to match the first column width
                background: '#fff',
                zIndex: 2, // Ensure it stays on top
                minWidth: '150px', // Ensure sufficient width for the "Field" column
                whiteSpace: 'nowrap', // Prevent text wrapping
              }}
            >
              Field
            </TableCell>
            {totals.map((total, index) => (
              <TableCell key={index} style={{ whiteSpace: 'nowrap' }}>
                {`W${total.period}-${total.period_year}`} {/* Ensure the W{period}-{period_year} stays on a single line */}
                <br />
                {getMondayDate(total.period, total.period_year)} {/* Display the Monday for each week */}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(fieldNameMapping)
            .filter((field) => !excludedFields.includes(field)) // Filter out the excluded fields
            .map((field, fieldIndex) => (
              <TableRow key={field}>
                {fieldIndex === 0 && (
                  <TableCell
                    rowSpan={Object.keys(fieldNameMapping).length - excludedFields.length + 2} // Account for Total Expenses and Profit rows
                    align="center"
                    style={{
                      position: 'sticky',
                      left: 0,
                      background: '#fff',
                      zIndex: 1, // Ensure it stays below the header
                      minWidth: '150px', // Ensure the truck number cell has enough width
                      whiteSpace: 'nowrap', // Prevent text wrapping
                      paddingLeft: 0, // Remove left padding to eliminate the gap
                    }}
                  >
                    <h3>{truckNumber}</h3>
                  </TableCell>
                )}
                <TableCell
                  style={{
                    position: 'sticky',
                    left: '150px', // Adjust to match the width of the first column
                    background: '#fff',
                    zIndex: 1, // Ensure it stays below the header
                    minWidth: '150px', // Ensure sufficient width for the field name
                    whiteSpace: 'nowrap', // Prevent text wrapping
                  }}
                >
                  {fieldNameMapping[field] || field} {/* Use mapped field names */}
                </TableCell>
                {totals.map((total, index) => (
                  <TableCell key={index}>
                    {renderFieldValue(total[field], field)}
                  </TableCell>
                ))}
              </TableRow>
            ))}

          {/* Total Expenses Row */}
          <TableRow>
            <TableCell
              style={{
                position: 'sticky',
                left: '150px',
                background: '#fff',
                zIndex: 1,
                fontWeight: 'bold',
              }}
            >
              Total Expenses
            </TableCell>
            {totals.map((total, index) => (
              <TableCell key={index}>
                {`$${calculateTotalExpenses(total).toFixed(2)}`}
              </TableCell>
            ))}
          </TableRow>

          {/* Profit Row */}
          <TableRow>
            <TableCell
              style={{
                position: 'sticky',
                left: '150px',
                background: '#fff',
                zIndex: 1,
                fontWeight: 'bold',
              }}
            >
              Profit
            </TableCell>
            {totals.map((total, index) => (
              <TableCell key={index}>
                {`$${calculateProfit(total).toFixed(2)}`}
              </TableCell>
            ))}
          </TableRow>

        </TableBody>
      </Table>
    </div>
  );
};

export default TotalTruckCard;
