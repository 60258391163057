import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import LoadCard from './LoadCard';
import LoadDetails from './LoadDetails';  // Import the detailed load page
import axios from 'axios';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// Function to get the current week number in Eastern Time Zone, adjusting for Monday and year boundary
const getCurrentWeek = () => {
  // Get current time in the Eastern Time Zone
  const now = new Date().toLocaleString("en-US", { timeZone: "America/New_York" });
  const nowDate = new Date(now);

  // Get the start of the year
  const startOfYear = new Date(nowDate.getFullYear(), 0, 1);

  // Calculate the number of days between now and the start of the year
  const days = Math.floor((nowDate - startOfYear) / (24 * 60 * 60 * 1000));

  // Calculate the current week number
  let weekNumber = Math.ceil((days + startOfYear.getDay() + 1) / 7);

  // Adjust for Monday: If today is Monday, move to the previous week
  if (nowDate.getDay() === 1) {
    weekNumber = weekNumber - 1;
  }

  // Handle year boundary case: If weekNumber is 0, it means we're in the last week of the previous year
  if (weekNumber < 1) {
    const lastDayOfPreviousYear = new Date(nowDate.getFullYear() - 1, 11, 31); // December 31st of the previous year
    const startOfPreviousYear = new Date(nowDate.getFullYear() - 1, 0, 1);
    const daysInPreviousYear = Math.floor((lastDayOfPreviousYear - startOfPreviousYear) / (24 * 60 * 60 * 1000));

    weekNumber = Math.ceil((daysInPreviousYear + startOfPreviousYear.getDay() + 1) / 7);
  }

  return weekNumber;
};

const Loads = () => {
  const [loads, setLoads] = useState([]);
  const [period, setPeriod] = useState(getCurrentWeek()); // Default to current week
  const [periodYear, setPeriodYear] = useState(new Date().getFullYear()); // Default to current year
  const [selectedLoad, setSelectedLoad] = useState(null); // Track the selected load for details

  useEffect(() => {
    const fetchLoads = async () => {
      try {
        const response = await axios.get(`/api/get_loads?period=${period}&period_year=${periodYear}`);

        // Debugging: Log the response to inspect the data
        console.log('API Response:', response);

        if (!response || !response.data) {
          console.error('Invalid response or data is null:', response);
          setLoads([]); // Handle null response
          return;
        }

        // Ensure response.data is an array before sorting
        if (Array.isArray(response.data)) {
          // Sort loads alphabetically by truck_id if the data is valid
          const sortedLoads = response.data.sort((a, b) => {
            const truckA = a.truck?.truck_id?.toLowerCase() || '';
            const truckB = b.truck?.truck_id?.toLowerCase() || '';
            return truckA.localeCompare(truckB);
          });

          setLoads(sortedLoads);
        } else {
          console.error('Invalid data format (not an array):', response.data);
          setLoads([]); // Fallback to an empty array
        }
      } catch (error) {
        console.error('Error fetching loads:', error);
      }
    };

    fetchLoads();
  }, [period, periodYear]);

  const handlePreviousWeek = () => {
    if (period === 1) {
      setPeriod(52); // Go to last week of the previous year
      setPeriodYear(periodYear - 1);
    } else {
      setPeriod(period - 1);
    }
  };

  const handleNextWeek = () => {
    const currentWeek = getCurrentWeek();
    const currentYear = new Date().getFullYear();

    if (period === currentWeek && periodYear === currentYear) {
      return; // Do nothing if it's already the current week
    }

    if (period === 52) {
      setPeriod(1); // Go to first week of the next year
      setPeriodYear(periodYear + 1);
    } else {
      setPeriod(period + 1);
    }
  };

  const handleCardClick = (load) => {
    setSelectedLoad(load); // Set the selected load for detailed view
  };

  const handleBackToLoads = () => {
    setSelectedLoad(null); // Deselect the load to go back to the load list
  };

  return (
    <Box sx={{ position: 'relative', minHeight: '100vh', backgroundColor: 'white' }}>
      {selectedLoad ? (
        // Show detailed load page if a load is selected
        <Box sx={{ padding: 4 }}>
          <IconButton onClick={handleBackToLoads} sx={{ marginBottom: 2 }}>
            <ArrowBackIcon /> {/* Go back to the list */}
          </IconButton>
          <LoadDetails load={selectedLoad} /> {/* Show the detailed page */}
        </Box>
      ) : (
        // Show the load cards if no load is selected
        <>
          <Grid container spacing={3} sx={{ padding: 4 }}>
            {loads.map((load, index) => (
              <Grid
                item
                xs={12}       // Full width on extra-small screens
                sm={6}        // Half width on small screens
                md={4}        // One-third width on medium screens
                lg={3}        // One-quarter width on large screens
                key={index}
              >
                {/* Click on the card to show load details */}
                <Box onClick={() => handleCardClick(load)} sx={{ cursor: 'pointer' }}>
                  <LoadCard load={load} />
                </Box>
              </Grid>
            ))}
          </Grid>

          {/* Icon buttons for navigating weeks - Fixed to the bottom right */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: 2 }}>
            <IconButton onClick={handlePreviousWeek} sx={{ marginRight: 1 }}>
              <ArrowBackIcon />
            </IconButton>
            <IconButton onClick={handleNextWeek}>
              <ArrowForwardIcon />
            </IconButton>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Loads;
