import React, { useEffect } from 'react';
import { ComposedChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const TotalTruckGraphs = ({ totals, truckNumber, startYear, startWeek, endYear, endWeek, averageData }) => {
  // Log the props data when the component mounts or updates
  useEffect(() => {
    console.log("Data passed into TotalTruckGraphs:");
    console.log("Truck Number:", truckNumber);
    console.log("Start Year:", startYear);
    console.log("Start Week:", startWeek);
    console.log("End Year:", endYear);
    console.log("End Week:", endWeek);
    console.log("Average Data:", averageData);
    console.log("Totals Data:", totals);
  }, [totals, truckNumber, startYear, startWeek, endYear, endWeek, averageData]);

  const calculateTotalExpenses = (total) => {
    const expenseFields = ['labor', 'rm', 'diesel', 'tolls', 'dispatch', 'payment', 'insurance', 'rental', 'cameras', 'admin', 'others'];
    const totalExpenses = expenseFields.reduce((sum, field) => {
      const value = total[field]?.Float64 || 0;
      return sum + value;
    }, 0);
    return parseFloat(totalExpenses.toFixed(2));
  };

  const calculateProfit = (total) => {
    const gross = total.gross?.Float64 || 0;
    const totalExpenses = calculateTotalExpenses(total);
    const profit = gross - totalExpenses;
    return parseFloat(profit.toFixed(2));
  };

  const data = totals.map((total) => ({
    period: `W${total.period}-${total.period_year}`,
    gross: total.gross?.Float64 || 0,
    miles: total.miles?.Float64 || 0,
    totalExpenses: calculateTotalExpenses(total),
    profit: calculateProfit(total),
  }));

  // Calculate averages for the truck's own data
  const calculateAverage = (key) => {
    const sum = data.reduce((acc, item) => acc + item[key], 0);
    return parseFloat((sum / data.length).toFixed(2));
  };

  const truckAverageData = {
    averageGross: calculateAverage('gross'),
    averageMiles: calculateAverage('miles'),
    averageTotalExpenses: calculateAverage('totalExpenses'),
    averageProfit: calculateAverage('profit'),
  };

  return (
    <div style={{ marginTop: '20px' }}>
      <h3>{`Graphs for Truck #${truckNumber}`}</h3>
      <ResponsiveContainer width="100%" height={400}>
        <ComposedChart
          data={data}
          margin={{
            top: 5, right: 30, left: 20, bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="period" />
          <YAxis />
          <Tooltip />
          <Legend />

          <Bar dataKey="gross" fill="#8884d8" name="Gross" />
          <Bar dataKey="miles" fill="#82ca9d" name="Miles" />
          <Bar dataKey="totalExpenses" fill="#ff8042" name="Total Expenses" />
          <Bar dataKey="profit" fill="#ffc658" name="Profit" />

          {/* Solid lines for truck's own average data */}
          <Line
            type="monotone"
            dataKey={() => truckAverageData.averageGross}
            stroke="#8884d8"
            name="Truck Avg Gross"
            dot={false}
          />
          <Line
            type="monotone"
            dataKey={() => truckAverageData.averageMiles}
            stroke="#82ca9d"
            name="Truck Avg Miles"
            dot={false}
          />
          <Line
            type="monotone"
            dataKey={() => truckAverageData.averageTotalExpenses}
            stroke="#ff8042"
            name="Truck Avg Total Expenses"
            dot={false}
          />
          <Line
            type="monotone"
            dataKey={() => truckAverageData.averageProfit}
            stroke="#ffc658"
            name="Truck Avg Profit"
            dot={false}
          />

          {/* Dashed lines for overall average data from TotalHolder, in lighter colors */}
          <Line
            type="monotone"
            dataKey={() => averageData.averageGross}
            stroke="#b0c4de" // Light steel blue
            strokeDasharray="3 3"
            name="Overall Avg Gross"
            dot={false}
          />
          <Line
            type="monotone"
            dataKey={() => averageData.averageMiles}
            stroke="#98fb98" // Pale green
            strokeDasharray="3 3"
            name="Overall Avg Miles"
            dot={false}
          />
          <Line
            type="monotone"
            dataKey={() => averageData.averageTotalExpenses}
            stroke="#f4a460" // Sandy brown
            strokeDasharray="3 3"
            name="Overall Avg Total Expenses"
            dot={false}
          />
          <Line
            type="monotone"
            dataKey={() => averageData.averageProfit}
            stroke="#ffd700" // Gold
            strokeDasharray="3 3"
            name="Overall Avg Profit"
            dot={false}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TotalTruckGraphs;
