import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Container } from '@mui/material';

const CreateCompany = () => {
  const [companyId, setCompanyId] = useState('');
  const [description, setDescription] = useState('');
  const [logo, setLogo] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('/api/create-company', {
        company_id: companyId,
        description: description,
        logo: logo,
      });
      alert('Company created: ' + response.data);
    } catch (error) {
      console.error('Error creating company:', error);
      alert('Failed to create company');
    }
  };

  return (
    <Container>
      <h2>Create Company</h2>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Company ID"
          value={companyId}
          onChange={(e) => setCompanyId(e.target.value)}
          fullWidth
          required
        />
        <TextField
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
        />
        <TextField
          label="Logo URL"
          value={logo}
          onChange={(e) => setLogo(e.target.value)}
          fullWidth
        />
        <Button type="submit" variant="contained" color="primary">
          Create Company
        </Button>
      </form>
    </Container>
  );
};

export default CreateCompany;
