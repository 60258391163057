import React, { useState, useEffect } from 'react';
import TotalTruckCard from './TotalTruckCard';
import { MenuItem, Select, FormControl, InputLabel, Grid, Button } from '@mui/material';
import TotalTruckGraphs from './TotalTruckGraphs';
import axios from 'axios';

const TotalHolder = () => {
  const [totalsByTruck, setTotalsByTruck] = useState({});
  const [trucks, setTrucks] = useState([]);
  const [selectedTruckId, setSelectedTruckId] = useState('');
  const [selectedTruckNumber, setSelectedTruckNumber] = useState('');
  const currentYear = new Date().getFullYear();
  const currentWeek = getWeekNumber(new Date());
  const [startPeriodYear, setStartPeriodYear] = useState(currentYear);
  const [startPeriod, setStartPeriod] = useState(1);
  const [endPeriodYear, setEndPeriodYear] = useState(currentYear);
  const [endPeriod, setEndPeriod] = useState(currentWeek);
  const [showGraphs, setShowGraphs] = useState(false);
  const [averageData, setAverageData] = useState({
    averageGross: 0,
    averageMiles: 0,
    averageTotalExpenses: 0,
    averageProfit: 0,
  });

  function getWeekNumber(date) {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstDayOfYear + 86400000) / 86400000;
    return Math.ceil(pastDaysOfYear / 7);
  }

  useEffect(() => {
    const fetchTrucks = async () => {
      try {
        const response = await fetch('/api/trucks');
        const data = await response.json();
        setTrucks(data);
        if (data.length > 0) {
          setSelectedTruckId(data[0].id); // Default to the first truck if available
          setSelectedTruckNumber(data[0].truck_id);
        }
      } catch (error) {
        console.error('Error fetching trucks:', error);
      }
    };

    fetchTrucks();
  }, []);

  useEffect(() => {
    const fetchTotalsForTrucks = async () => {
      try {
        const totalsData = {};
        for (const truck of trucks) {
          const response = await fetch(
            `/api/totals?truck_id=${truck.id}&start_year=${startPeriodYear}&start_week=${startPeriod}&end_year=${endPeriodYear}&end_week=${endPeriod}`
          );
          const data = await response.json();
          totalsData[truck.id] = data;
        }
        setTotalsByTruck(totalsData);
      } catch (error) {
        console.error('Error fetching totals for trucks:', error);
      }
    };

    if (trucks.length > 0) {
      fetchTotalsForTrucks();
    }
  }, [trucks, startPeriodYear, startPeriod, endPeriodYear, endPeriod]);

  useEffect(() => {
    const fetchAverageData = async () => {
      try {
        const response = await axios.get(`/api/trucks_best`, {
          params: {
            start_year: startPeriodYear,
            start_week: startPeriod,
            end_year: endPeriodYear,
            end_week: endPeriod,
            all: true,
          },
        });
        const averageData = response.data[0];

        // Ensure we access numeric values correctly, falling back to 0 if undefined
        const averageGross = averageData.gross?.Float64 ?? 0;
        const averageMiles = averageData.miles?.Float64 ?? 0;

        // Calculate total expenses by summing specified fields
        const calculateTotalExpenses = (data) => {
          const expenseFields = [
            'labor', 'rm', 'diesel', 'tolls', 'dispatch', 'payment',
            'insurance', 'rental', 'cameras', 'admin', 'others'
          ];
          return expenseFields.reduce((sum, field) => {
            const value = data[field]?.Float64 ?? 0;
            return sum + value;
          }, 0);
        };

        const averageTotalExpenses = parseFloat(calculateTotalExpenses(averageData).toFixed(2));
        const averageProfit = parseFloat((averageGross - averageTotalExpenses).toFixed(2));

        setAverageData({
          averageGross: parseFloat(averageGross),
          averageMiles: parseFloat(averageMiles),
          averageTotalExpenses: averageTotalExpenses,
          averageProfit: averageProfit,
        });
      } catch (error) {
        console.error("Error fetching average data:", error);
      }
    };

    fetchAverageData();
  }, [startPeriodYear, startPeriod, endPeriodYear, endPeriod]);

  const handleTruckChange = (event) => {
    const selectedTruck = trucks.find((truck) => truck.id === event.target.value);
    setSelectedTruckId(selectedTruck.id);
    setSelectedTruckNumber(selectedTruck.truck_id);
  };

  const handleToggleView = () => {
    setShowGraphs(!showGraphs);
  };

  return (
    <div style={{ padding: '16px' }}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel id="start-year-label">Start Year</InputLabel>
            <Select
              labelId="start-year-label"
              value={startPeriodYear}
              onChange={(e) => setStartPeriodYear(e.target.value)}
            >
              {Array.from({ length: 11 }, (_, index) => currentYear - index).map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="start-period-label">Start Period (Week)</InputLabel>
            <Select
              labelId="start-period-label"
              value={startPeriod}
              onChange={(e) => setStartPeriod(e.target.value)}
            >
              {Array.from({ length: startPeriodYear === currentYear ? currentWeek : 52 }, (_, index) => index + 1).map((week) => (
                <MenuItem key={week} value={week}>
                  {week}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel id="truck-select-label">Select Truck</InputLabel>
            <Select
              labelId="truck-select-label"
              value={selectedTruckId}
              onChange={handleTruckChange}
            >
              {trucks.map((truck) => (
                <MenuItem key={truck.id} value={truck.id}>
                  {truck.truck_id}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel id="end-year-label">End Year</InputLabel>
            <Select
              labelId="end-year-label"
              value={endPeriodYear}
              onChange={(e) => setEndPeriodYear(e.target.value)}
            >
              {Array.from({ length: 11 }, (_, index) => currentYear - index).map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="end-period-label">End Period (Week)</InputLabel>
            <Select
              labelId="end-period-label"
              value={endPeriod}
              onChange={(e) => setEndPeriod(e.target.value)}
            >
              {Array.from({ length: endPeriodYear === currentYear ? currentWeek : 52 }, (_, index) => index + 1).map((week) => (
                <MenuItem key={week} value={week}>
                  {week}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Button variant="contained" color="primary" onClick={handleToggleView} style={{ marginTop: '16px' }}>
        {showGraphs ? 'Show Table' : 'Show Graphs'}
      </Button>

      {!showGraphs ? (
        <div>
          {selectedTruckId && (
            <TotalTruckCard
              key={selectedTruckId}
              truckId={selectedTruckId}
              truckNumber={selectedTruckNumber}
              totals={totalsByTruck[selectedTruckId] || []}
              startPeriod={{ year: startPeriodYear, period: startPeriod }}
              endPeriod={{ year: endPeriodYear, period: endPeriod }}
            />
          )}

          {trucks
            .filter((truck) => truck.id !== selectedTruckId && truck.truck_id) // Exclude selected truck and empty truck IDs
            .map((truck) => (
              <TotalTruckCard
                key={truck.id}
                truckId={truck.id}
                truckNumber={truck.truck_id}
                totals={totalsByTruck[truck.id] || []}
                startPeriod={{ year: startPeriodYear, period: startPeriod }}
                endPeriod={{ year: endPeriodYear, period: endPeriod }}
              />
            ))}
        </div>
      ) : (
        selectedTruckId && (
          <TotalTruckGraphs
            totals={totalsByTruck[selectedTruckId] || []}
            truckNumber={selectedTruckNumber}
            startYear={startPeriodYear}
            startWeek={startPeriod}
            endYear={endPeriodYear}
            endWeek={endPeriod}
            averageData={averageData} // Pass average data to the graph
          />
        )
      )}
    </div>
  );
};

export default TotalHolder;
