import React from 'react';
import { Card, CardContent, Typography, Grid, Box, Divider } from '@mui/material';

const LoadDetails = ({ load }) => {
  return (
    <Box p={4}>
      <Card>
        <CardContent>
          <Typography variant="h4" gutterBottom>
            Load Details
          </Typography>

          {/* General Information */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">General Information</Typography>
              <Divider />
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Load ID:</strong> {load.load_id?.String || 'N/A'}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Chat ID:</strong> {load.chat_id?.Int64 || 'N/A'}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Truck:</strong> {load.truck?.truck_id || 'N/A'}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Trailer:</strong> {load.trailer?.trailer_id || 'N/A'}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Driver:</strong> {load.driver ? `${load.driver.first_name} ${load.driver.last_name}` : 'N/A'}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Gross:</strong> {load.gross?.Float64 ? `$${load.gross.Float64}` : 'N/A'}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Distance:</strong> {load.distance?.Float64 ? `${load.distance.Float64} miles` : 'N/A'}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Oversize:</strong> {load.oversize?.Bool ? 'Yes' : 'No'}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Tarp:</strong> {load.tarp?.Bool ? 'Yes' : 'No'}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Canceled:</strong> {load.canceled?.Bool ? 'Yes' : 'No'}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Accountant Checked:</strong> {load.accountant_checked?.Bool ? 'Yes' : 'No'}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Dispatch Checked:</strong> {load.dispatch_checked?.Bool ? 'Yes' : 'No'}
              </Typography>
            </Grid>
          </Grid>

          {/* Period Information */}
          <Box mt={4}>
            <Typography variant="h6">Period Information</Typography>
            <Divider />
            <Grid container spacing={2} mt={2}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <strong>Period:</strong> {load.period || 'N/A'}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body1">
                  <strong>Period Year:</strong> {load.period_year || 'N/A'}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1">
                  <strong>Created At:</strong> {new Date(load.created_at).toLocaleString()}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          {/* Location Information */}
          <Box mt={4}>
            <Typography variant="h6">Locations</Typography>
            <Divider />
            {load.locations && load.locations.length > 0 ? (
              load.locations.map((location, index) => (
                <Box key={index} mt={2}>
                  <Typography variant="body1">
                    <strong>Location {index + 1}:</strong> {location.location?.address?.String || 'N/A'}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Time In:</strong> {location.time_in?.String || 'N/A'}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Time Out:</strong> {location.time_out?.String || 'N/A'}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Timestamp In:</strong> {new Date(location.time_stamp_in).toLocaleString()}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Timestamp Out:</strong> {new Date(location.time_stamp_out).toLocaleString()}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography variant="body2">No locations available</Typography>
            )}
          </Box>

          {/* Checks Information */}
          <Box mt={4}>
            <Typography variant="h6">Checks</Typography>
            <Divider />
            {load.checks && load.checks.length > 0 ? (
              load.checks.map((check, index) => (
                <Box key={index} mt={2}>
                  <Typography variant="body1">
                    <strong>Check {index + 1}:</strong> {check.info?.String || 'N/A'}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Type:</strong> {check.type || 'N/A'}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Cost:</strong> {check.cost?.Float64 ? `$${check.cost.Float64}` : 'N/A'}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography variant="body2">No checks available</Typography>
            )}
          </Box>

          {/* Documents Information */}
          <Box mt={4}>
            <Typography variant="h6">Documents</Typography>
            <Divider />
            {load.documents && load.documents.length > 0 ? (
              load.documents.map((document, index) => (
                <Box key={index} mt={2}>
                  <Typography variant="body1">
                    <strong>Document {index + 1}:</strong> {document.info?.String || 'N/A'}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Type:</strong> {document.type || 'N/A'}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography variant="body2">No documents available</Typography>
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default LoadDetails;
