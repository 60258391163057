import React, { useEffect, useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  CircularProgress, Container, Typography
} from '@mui/material';

function TotalTableOld() {
  const [totals, setTotals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch total records from the API
  useEffect(() => {
    fetch('/api/totals')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setTotals(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <Container style={{ textAlign: 'center', marginTop: '20px' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container style={{ textAlign: 'center', marginTop: '20px' }}>
        <Typography variant="h6" color="error">{error}</Typography>
      </Container>
    );
  }

  return (
    <Container style={{ marginTop: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Total Records
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><b>Truck</b></TableCell>
              <TableCell><b>Period</b></TableCell>
              <TableCell><b>Period Year</b></TableCell>
              <TableCell><b>Miles Real</b></TableCell>
              <TableCell><b>Labor</b></TableCell>
              <TableCell><b>RM</b></TableCell>
              <TableCell><b>Fuel Efficiency</b></TableCell>
              <TableCell><b>Tolls</b></TableCell>
              <TableCell><b>Dispatch</b></TableCell>
              <TableCell><b>Payment</b></TableCell>
              <TableCell><b>Insurance</b></TableCell>
              <TableCell><b>Rental</b></TableCell>
              <TableCell><b>Cameras</b></TableCell>
              <TableCell><b>Admin</b></TableCell>
              <TableCell><b>Others</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {totals.map((total) => (
              <TableRow key={total.id}>
                <TableCell>{total.truck && total.truck.truck_id ? total.truck.truck_id : 'N/A'}</TableCell>
                <TableCell>{total.period}</TableCell>
                <TableCell>{total.period_year}</TableCell>
                <TableCell>{total.miles_real.Valid ? total.miles_real.Float64 : 'N/A'}</TableCell>
                <TableCell>{total.labor.Valid ? total.labor.Float64 : 'N/A'}</TableCell>
                <TableCell>{total.rm.Valid ? total.rm.Float64 : 'N/A'}</TableCell>
                <TableCell>{total.fuel_efficiency.Valid ? total.fuel_efficiency.Float64 : 'N/A'}</TableCell>
                <TableCell>{total.tolls.Valid ? total.tolls.Float64 : 'N/A'}</TableCell>
                <TableCell>{total.dispatch.Valid ? total.dispatch.Float64 : 'N/A'}</TableCell>
                <TableCell>{total.payment.Valid ? total.payment.Float64 : 'N/A'}</TableCell>
                <TableCell>{total.insurance.Valid ? total.insurance.Float64 : 'N/A'}</TableCell>
                <TableCell>{total.rental.Valid ? total.rental.Float64 : 'N/A'}</TableCell>
                <TableCell>{total.cameras.Valid ? total.cameras.Float64 : 'N/A'}</TableCell>
                <TableCell>{total.admin.Valid ? total.admin.Float64 : 'N/A'}</TableCell>
                <TableCell>{total.others.Valid ? total.others.Float64 : 'N/A'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default TotalTableOld;
